export default{
   units:[
      "m",
      "m²",
      "m³",
      "g",
      "kg",
      "ton",
      "l",
      "h",
      "dia",
      "sem",
      "mês",
      "unid",
      "fl",
      "cento",
      "vb",
      "bd",
      "caixa",
      "pç",
      "jg",
      "rl",
      "vg",
      "gl",
      "lt",
      "mil",
      "pct",
      "sc",
      
   ]
}