<template>
<v-container>
  <v-data-table :loading="loading_data_table" :headers="headers" :items="historics" sort-by="corporate_name" class="elevation-1" :pageCount="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Histórico de Empreteiro</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Localizar" @click:append="searchItems()" @change="searchItems()" single-line hide-details class="mr-8"></v-text-field>
      </v-toolbar>
    </template>

    <template v-slot:item.period="{ item }">
            {{ formatPeriod(item) }}
         <!-- {{ formatDate(item.date_start) + ' - '+ formatDate(item.date_end) }} -->
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip small v-if="item.approved == 'yes'" color="success">Aprovado</v-chip>
      <v-chip small v-if="item.approved == 'not'" color="warning">Não Aprovado</v-chip>
    </template>
    <template v-slot:item.actions="{ item }">

 

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" icon class="mr-2" @click="viewSummary(item)">
            <v-icon> mdi-eye </v-icon>
          </v-btn>
        </template>
        <span>Visualizar Medições</span>
      </v-tooltip>

    </template>

    <template v-slot:no-data> Nenhum registro encontrado </template>

  </v-data-table>


  <DialogContractorSummary v-model="dialogSummary" :contract="meteringContract"></DialogContractorSummary>
</v-container>
</template>

<script>
import DialogContractorSummary from "./DialogContractorSummary.vue"
import {
  mapActions
} from "vuex/dist/vuex.common.js";
// import BarTitle from "@/pages/Components/BarTitle.vue";
export default {
  components: {
    DialogContractorSummary,
  },
  data: () => ({
    dialogSummary:false,
    meteringContract:{},

    historics: [],
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    search: "",
    loading_data_table: false,
    dialog: false,
    headers: [{
        text: "MEDIÇÃO",
        align: "start",
        sortable: false,
        value: "metering_n",
      },
      {
        text: "PERÍODO",
        value: "period",
      },
      {
        text: "STATUS",
        value: "status",
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
  }),

  computed: {},

  watch: {
    value(val) {
      if (val) {
        //carrega o hitorico
        this.initialize()
      }
    },

    dialog(val) {
      val || this.close();
    },

    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("Contractor", [
      'getHistoric'
    ]),
    initialize() {
      //carrega a loista dos empreiteiros
      this.loading_data_table = true;
      const {
        page,
        itemsPerPage
      } = this.options;

      const data = {
        contractor_id: this.$route.params.contractor_id,
        itemsPerPage,
        page,
        search: this.search,
      }

      this.getHistoric(data).then((response) => {
        this.historics = response.data.data;
        this.totalStagesPaginate = response.data.total;
        this.numberOfPagesPaginate = response.data.last_page;
        this.currentPagePaginate = response.data.current_page;
        this.loading_data_table = false;
      });
    },

    searchItems() {
      this.options = {};
      this.initialize();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    updateContractor() {
      this.loadingRefresh = true;
      this.getUpdateContractor()
        .then(() => {
          this.$toast.success("Atualizado com sucesso.");
          this.initialize();
        })
        .finally(() => {
          this.loadingRefresh = false;
        });
    },
    viewSummary(metering_contract){
        this.meteringContract = metering_contract
        // console.log(this.meteringContract)
         this.dialogSummary = true
    },

    formatDate(date) {
      let date_split = date.split("-");
      return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
    },
    formatPeriod(item){

        if(item){
             if(item.date_start != null && item.date_end != null){
                 return this.formatDate(item.date_start) + ' - '+ this.formatDate(item.date_end)
             }
        }

        return ''
    },


  },
};
</script>
