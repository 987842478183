<template>
<div class="bar_title">
    <v-row no-gutters>
        <v-col cols="2">

        </v-col>

        <v-col class="text_bar_title text-center pt-1">
            {{ title.toUpperCase() }}
        </v-col>

        <v-col cols="2" class="text-right">
            <v-btn icon dark @click="close()">
                <v-icon dark> mdi-close </v-icon>
            </v-btn>
        </v-col>
    </v-row>


</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Title'
        }
    },
    data: () => ({

    }),

    methods:{
        close(){
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.text_bar_title {
    color: white;
    font-size: 19px;
}
.bar_title{
  background:#1976D2;
  width:100%;
  height:45px;
  box-sizing:border-box;
  padding:5px;
  margin-bottom:20px
}
</style>
